import React, { forwardRef, useState } from "react";

import { DISABLED_STYLES, FOCUS_STYLES } from "../constants";

function Input({ className = "", type, ...otherProps }, ref) {

  const [autocompleteList, setAutocompleteList] = useState([]);
  const [value, setValue] = useState(otherProps.value);

  const autocompleteSearch = (input, list) => {
    const filteredData = list.reduce((accumulator, currentItem) => {
      const existingItem = accumulator.find(item => item.code === currentItem.code);
      if (!existingItem) {
        accumulator.push(currentItem);
      }
      return accumulator;
    }, []);

    // Convert input to lowercase for case-insensitive matching
    const lowercaseInput = input.toLowerCase();

    // Filter the list array based on the matching criteria
    const filteredList = filteredData.filter(item => {
      const { name, code, country, city } = item;

      // Convert fields to lowercase for case-insensitive matching
      const lowercaseName = name.toLowerCase();
      const lowercaseCode = code.toLowerCase();
      const lowercaseCountry = country.toLowerCase();
      const lowercaseCity = city.toLowerCase();

      // Check if any of the fields match the lowercase input
      return (
        lowercaseName.startsWith(lowercaseInput) ||
        lowercaseCode.startsWith(lowercaseInput) ||
        lowercaseCountry.startsWith(lowercaseInput) ||
        lowercaseCity.startsWith(lowercaseInput)
      );
    });

    return filteredList;
  }

  const handleValueChange = (event) => {
    const newValue = event.target.value;
    setValue(newValue);
    const filteredList = autocompleteSearch(newValue, otherProps.autocompletedata);
    setAutocompleteList(filteredList);
  };

  const handleMultipleValueChange = (event) => {
    const newValueParts = event.target.value.split(',');
    const newValue = newValueParts[newValueParts.length - 1]
    setValue(newValueParts.join(','));
    const filteredList = autocompleteSearch(newValue, otherProps.autocompletedata);
    setAutocompleteList(filteredList);
  };

  const handleAutoCompleteClick = (event) => {
    const newValue = event.target.textContent.split('|')[0].trim();
    setValue(newValue);
    setAutocompleteList([]);
  };

  const handleAutoCompleteMultipleClick = (event) => {
    let originalValue = value.substring(0, value.lastIndexOf(','))
    originalValue = originalValue.length ? `${originalValue},` : originalValue
    const newValue = event.target.textContent.split('|')[0].trim();

    setValue(`${originalValue}${newValue},`);
    setAutocompleteList([]);
  };

  const handleAutoCompleteCloseClick = () => {
    setValue('');
    setAutocompleteList([]);
  };

  const handleClearClick = () => {
    setValue('');
  };



  if (otherProps.autocompletedata !== undefined) {
    otherProps.value = value;

    return (
      <>
        {type === 'textarea' && (
          <textarea
            ref={ref}
            className={[
              "font-medium text-sm text-white border border-opacity-50 px-3 py-1 rounded-md",
              "border-neutral-800 bg-neutral-900 hover:border-neutral-700",
              "min-w-0 placeholder:text-neutral-400",
              DISABLED_STYLES,
              FOCUS_STYLES,
              className,
            ].join(" ")}
            {...otherProps}
          />
        )}
        {type !== 'textarea' && (
          <input
            ref={ref}
            className={[
              "font-medium text-sm text-white border border-opacity-50 px-3 py-1 rounded-md",
              "border-neutral-800 bg-neutral-900 hover:border-neutral-700",
              "min-w-0 placeholder:text-neutral-400",
              DISABLED_STYLES,
              FOCUS_STYLES,
              className,
            ].join(" ")}
            onChange={otherProps.multiple ? handleMultipleValueChange : handleValueChange}
            {...otherProps}
          />
        )}
        <span
          onClick={handleClearClick}
          className="clear"
        >╳</span>

        {autocompleteList.length > 0 && (
          <div className="autocomplete-special">
            <button onClick={handleAutoCompleteCloseClick}>╳</button>
            <ul>
              {autocompleteList.map((item, index) => (
                <li
                  className="my-3 font-medium text-sm text-white border border-opacity-50 px-3 py-1 rounded-md block border-blue-400 bg-blue-500 hover:border-blue-300 disabled:cursor-not-allowed disabled:opacity-60 disabled:border-transparent disabled:hover:border-transparent focus:outline-none focus-visible:outline-dashed focus-visible:outline-1 focus-visible:outline-offset-2 focus-visible:outline-blue-500 flex-1"
                  onClick={otherProps.multiple ? handleAutoCompleteMultipleClick : handleAutoCompleteClick}
                  key={index}
                >
                  {item.code} | {item.name}
                </li>
              ))}
            </ul>
          </div>
        )}
      </>
    );
  } else {
    return (
      <>
        {type === 'textarea' && (
          <textarea
            ref={ref}
            className={[
              "font-medium text-sm text-white border border-opacity-50 p-3 rounded-md",
              "border-neutral-800 bg-neutral-900 hover:border-neutral-700",
              "min-w-0 h-auto placeholder:text-neutral-400",
              "w-full", // Add w-full class for 100% width
              DISABLED_STYLES,
              FOCUS_STYLES,
              className,
            ].join(" ")}
            {...otherProps}
          />

        )}
        {type !== 'textarea' && (
          <input
            ref={ref}
            className={[
              "font-medium text-sm text-white border border-opacity-50 px-3 py-1 rounded-md",
              "border-neutral-800 bg-neutral-900 hover:border-neutral-700",
              "min-w-0 placeholder:text-neutral-400",
              DISABLED_STYLES,
              FOCUS_STYLES,
              className,
            ].join(" ")}
            {...otherProps}
          />
        )}
      </>

    );
  }
}

export default forwardRef(Input);

