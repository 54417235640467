import { CURRENCY_OPTIONS } from "../constants";

export function getCurrencySymbolFromCurrencyValue(value) {
  return CURRENCY_OPTIONS.find(function (option) {
    return option.value === value;
  }).symbol;
}

export function getPostPriceFromCurrencyValue(value) {
  return CURRENCY_OPTIONS.find(function (option) {
    return option.value === value;
  }).pricePost;
}

export function capitalizeSentence(sentence = "") {
  return sentence
    .split(" ")
    .map(function (word) {
      return `${word.charAt(0).toUpperCase()}${word.slice(1)}`;
    })
    .join(" ");
}

export function getRandomInRange(max = 1) {
  return Math.round(Math.random() * max);
}

export function titleToLinkConverter(title) {
  title = title.replace(/ /g, '-');
  title = title.replace(/'/g, '');
  title = title.substring(0, 100);
  title = title.toLowerCase();
  return encodeURIComponent(title)
}


// Function to find matching items and sort by lowest price
export const findMatches = async (originList, destinationList) => {
  const matchingItems = [];

  // Flatten the arrays and find matching skyId items
  const originItems = originList.flat();
  const destinationItems = destinationList.flat();

  originItems.forEach((originItem) => {
    const matchingDestination = destinationItems.find(
      (destinationItem) => originItem.skyId === destinationItem.skyId
    );

    if (matchingDestination) {
      matchingItems.push({
        skyId: originItem.skyId,
        entityId: originItem.entityId,
        price: Math.min(originItem.price, matchingDestination.price),
      });
    }
  });

  // Sort the matching items by lowest price
  matchingItems.sort((a, b) => a.price - b.price);

  return matchingItems;
}
